const url = {
    newsList:"/oa/oa-news/listByUser",
    login:"/oa/sys/login",
    statementList:"/oa/statement/listByUser",
    documentList:"/oa/doc/listByUser",
    userInfor:"/oa/sys/user/info",
    upDateUserInfor:"/oa/sys/user/updateMe",
    employmentUpdate:"/oa/oa-employment/updateOrInsert",
    employmentInfor:"/oa/oa-employment/info",
    financialInfor:"/oa/financial/info",
    financialUpdate:"/oa/financial/updateByUser",
    newsDetails:"/oa/oa-news/info/",
    resstPassword:"/oa/sys/user/password",
    loginEmialCode:"/oa/email/send/login/code",
    loginMoblieCode:"/oa/moblie/send/login/code",
    forgetPassword:"/oa/sys/ResetPassword", //提交重置

    forgetCodeM:"/oa/moblie/send/reset/code",
    forgetCodeE:"/oa/email/send/reset/code",

    fundsummary:"/oa/fund/info/summary",
    fundclosedposltion:"/oa/fund/fundclosedposltion/userList",
    fundopendsummary:"/oa/fund/fundopendsummary/userList",
    funddownload:"/oa/fund/info/pdf/download",
    fundfileinfor:"/oa/fund-file-info/userFundInfo",
    contactUs:"/oa/oalink/save",
    documentTypeList:"/oa/sys/sysdictdata/infoByType/document_type"
}
export default url