<template>
    <div class="header">
        <div class="headerBox" :class="{isabsolute:isFixed}">
            <div class="headerContent content" v-if="$route.name !='Login'&& $route.name!==null">
                <div class="left">
                    <div class="logo">
                        <router-link to="/home" tag="a"><img src="../assets/images/login-logo.png"></router-link>
                        <!-- <router-link to="/home" tag="a" v-else><img src="../assets/images/logo-w.png"></router-link> -->
                    </div>
                    <div class="nav">
                        <ul>
                            <li>
                                <router-link to="/home" :class="{active:$route.meta.active=='1'}" tag="span">{{$t('oa.Home')}}</router-link>
                            </li>
                            <li>
                                <router-link to="/aboutUs" :class="{active:$route.meta.active=='8'}" tag="span">{{$t('oa.AboutUs')}}</router-link>
                            </li>
                            <li>
                                <router-link to="/news" :class="{active:$route.meta.active=='2'}" tag="span">{{$t('oa.News')}}</router-link>
                            </li>
                            <li>
                                <router-link to="/dailyPosition" :class="{active:$route.meta.active=='6'}" tag="span">{{$t('oa.statement')}}</router-link>
                            </li>
                            <li>
                                <router-link to="/contactUs" :class="{active:$route.meta.active=='7'}" tag="span">{{$t('oa.coutcontactus')}}</router-link>
                            </li>
                            <li class="download">
                                <span>{{$t('oa.DocumentDownLoad')}}</span>
                                 <div class="downQRBox">
                                        <div class="apk view">
                                            <img src="../../public/AHSApk.png" />
                                            <div class="text">Android</div>
                                        </div>
                                        <div class="api view">
                                            <img src="../../public/AHSApi.png" />
                                            <div class="text">IOS</div>
                                        </div>
                                    </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="right">
                    <div class="userInfor">{{$t('oa.Welcomeh')}} ! {{userName}}</div>
                    <el-dropdown type="primary" trigger="hover">
                        <div class="user" split-button></div>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item>
                                <router-link tag="div" to="/account">{{$t('oa.account')}}</router-link>
                            </el-dropdown-item>
                            <el-dropdown-item>
                                <div @click="centerDialogVisible2 = true">{{$t('oa.Privacypolicy')}}</div>
                            </el-dropdown-item>
                            <el-dropdown-item>
                                <div @click="centerDialogVisible1 = true">{{$t('oa.UserAgreement')}}</div>
                            </el-dropdown-item>
                            <el-dropdown-item>
                                <div @click="changePasswodDialog = true">{{$t('oa.reset')}}</div>
                            </el-dropdown-item>
                            <el-dropdown-item>
                                <router-link tag="div" to="/contactUs">{{$t('oa.help')}}</router-link>
                            </el-dropdown-item>
                            <el-dropdown-item>
                                <div @click="loginOut">{{$t('oa.loginOut')}} </div>
                            </el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                    <el-dropdown type="primary" trigger="hover">
                        <div class="lang" split-button>{{defaultLang}}</div>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item v-for="(item,index) in langList" :key="index" @click.native="checkLang(item)">{{item.text}}</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </div>
        </div>
        <el-dialog append-to-body :visible.sync="centerDialogVisible1" width="50%" center title="User Agreement">
            <userAgreement />
        </el-dialog>
        <el-dialog append-to-body :visible.sync="centerDialogVisible2" width="50%" center title="Privacy Policy">
            <privacyP />
        </el-dialog>
        <el-dialog class="changePasswordBox" :title="$t('oa.reset')" :visible.sync="changePasswodDialog" :show-close="isFristChangePassword" :close-on-press-escape="isFristChangePassword" :close-on-click-modal="isFristChangePassword" width="25%">
            <el-form :model="ruleForm" :rules="rules" ref="resstPassword">
                <el-form-item :label="$t('oa.oldPassword')" prop="password">
                    <el-input v-model="ruleForm.password" type="password" :placeholder="$t('oa.enterOldPassword')"></el-input>
                </el-form-item>
                <el-form-item :label="$t('oa.newPassword')" prop="newPassword">
                    <el-input v-model="ruleForm.newPassword" type="password" :placeholder="$t('oa.enterNewPassword')"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <!-- <el-button type="danger" @click="changePasswodDialog = false">{{$t('oa.cancel')}}</el-button> -->
                <el-button class="changpasswordBtn" type="danger" @click="chnagePasswod('resstPassword')" :loading="changePasswodloading">{{$t('oa.confirm')}}</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import url from "@/api/url.js";
import privacyP from "@/components/privacyPolicy.vue";
import userAgreement from "@/components/userAgreement.vue";
export default {
    name: "Header",
    components: {
        userAgreement,
        privacyP,
    },
    props: {
        isFixed: {
            type: Boolean,
            default: () => false,
        },
    },
    data() {
        return {
            defaultLang: "CN",
            userName: "",
            changePasswodDialog: false,
            changePasswodloading: false,
            isFristChangePassword: true,
            forgetView: false,
            sendMsgDisabled: false,
            loginTimer: 60,
            smsLogind: false,
            submitLoading: false,
            centerDialogVisible1: false,
            centerDialogVisible2: false,
            langList: [
                {
                    text: "English",
                    lang: "EN",
                },
                {
                    text: "中文繁体",
                    lang: "TC",
                },
                {
                    text: "中文简体",
                    lang: "CN",
                },
            ],
            ruleForm: {
                newPassword: "",
                password: "",
                type: 2,
            },
            rules: {
                password: [
                    {
                        required: true,
                        message: "请输入旧密码",
                        trigger: "blur",
                    },
                ],
                newPassword: [
                    {
                        required: true,
                        message: "请输入新密码",
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    created() {
        this.getUserInfor();
        const lang = localStorage.getItem("AUTLang");
        const userName = localStorage.getItem("userName");
        this.userName = userName;
        this.$i18n.locale = lang || "CN";
        this.defaultLang = lang || "CN";
    },
    watch: {
        "$i18n.locale": function (newVal) {
            this.initLang();
        },
    },
    methods: {
        initLang() {
            this.rules.password[0].message = this.$t("oa.enterOldPassword");
            this.rules.newPassword[0].message = this.$t("oa.enterNewPassword");
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },
        checkLang(e) {
            this.defaultLang = e.lang;
            this.$i18n.locale = e.lang;
            localStorage.setItem("AUTLang", e.lang);
        },
        loginOut() {
            localStorage.removeItem("AUTtoken");
            localStorage.removeItem("userName");
            this.$router.replace({ name: "Login" });
            this.$message({
                type: "error",
                message: this.$t("oa.LogOut"),
            });
        },
        getUserInfor() {
            this.$axios.get(url.userInfor).then((res) => {
                if (res.user.pwdStatus != "Y") {
                    this.changePasswodDialog = true;
                    this.isFristChangePassword = false;
                }
            });
        },

        chnagePasswod(formName) {
            if (this.changePasswodloading) return;
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.changePasswodloading = true;
                    this.$axios
                        .post(url.resstPassword, {
                            ...this.ruleForm,
                        })
                        .then((res) => {
                            this.$message({
                                message: this.$t("oa.steSuccess"),
                                type: "success",
                            });
                            this.changePasswodDialog = false;
                        })
                        .finally(() => {
                            this.resetForm(formName);
                            this.changePasswodloading = false;
                        });
                } else {
                    return false;
                }
            });
        },
    },
};
</script>


<style lang="scss" scoped>
.header {
    .changePasswordBox {
        /deep/.el-dialog {
            padding: 20px;
            margin-top: 20vh !important;
            border-radius: 10px;
        }
    }
    /deep/.el-dialog__title {
        font-weight: bold;
    }
    /deep/.el-input__inner {
        height: 50px;
    }
    /deep/.el-dialog__footer {
        display: flex;
        justify-content: center;
    }
    /deep/.dialog-footer {
        width: 100%;
    }
    /deep/.el-form-item {
        margin-bottom: 15px;
    }
    /deep/.el-dialog__body {
        padding: 20px;
    }
    .changpasswordBtn {
        background: linear-gradient(90deg, #305a9e 0%, #0e6fa7 100%);
        border: none;
        width: 100%;
        height: 55px;
        border-radius: 30px;
        /deep/span {
            font-size: 24px !important;
        }
    }
}

.headerBox {
    width: 100%;
    height: 100px;
    background-color: #fff;
    /deep/.el-dropdown-menu__item:focus,
    .el-dropdown-menu__item:not(.is-disabled):hover {
        color: #305a9e;
    }
    /deep/.el-input__inner:focus {
        border-color: #eee;
    }
    .loginOut {
        height: 30px;
        line-height: 30px;
        background-color: #305a9e;
        color: #fff;
        padding: 0 20px;
        cursor: pointer;
        border-radius: 5px;
        margin-right: 20px;
        span {
            font-size: 12px;
        }
    }
    .headerContent {
        width: 1320px;
        height: 100px;
        position: absolute;
        z-index: 99;
        left: 50%;
        transform: translateX(-50%);
        top: 0;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left {
            display: flex;
            align-items: center;
            .logo {
                padding-right: 20px;
                // img {
                //     width: 124px;
                // }
            }
            .nav {
                ul {
                    display: flex;
                    li {
                        margin: 0 20px;
                        display: flex;
                        align-items: center;

                        span {
                            cursor: pointer;
                            display: flex;
                            align-items: center;
                            color: #333;
                            transition: color 0.3s ease-in-out;
                            position: relative;
                            line-height: 40px;
                            position: relative;
                            font-size: 15px;
                        }
                        span.active {
                            color: #305a9e;
                        }
                        span.active::after {
                            display: block;
                            content: "";
                            transition: 0.3s linear;
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            width: 100%;
                            border: 1px solid #305a9e;
                        }
                    }
                    .download {
                        position: relative;
                        .downQRBox {
                            position: absolute;
                            top: 50px;
                            left: 50%;
                            z-index: 9;
                            transform: translateX(-50%);
                            border-radius: 4px;
                            display: flex;
                            padding: 15px;
                            padding-top: 30px;
                            height: 200px;
                            visibility: hidden;
                            opacity: 0;
                            transition: all .3s linear;
                            box-shadow: 0 0 15px rgba(100,100,100,.3) ;
                            background-color: #fff;
                            .view {
                                margin: 0 10px;
                                img {
                                    width: 120px;
                                }
                                .text {
                                    text-align: center;
                                    line-height: 40px;
                                    font-size: 18px;
                                }
                            }
                        }
                        .downQRBox::after {
                            content: "";
                            display: block;
                            width: 0;
                            height: 0;
                            border-left: 10px solid transparent;
                            border-right: 10px solid transparent;
                            border-bottom: 10px solid #fff;
                            position: absolute;
                            top: -10px;
                            left: 50%;
                            margin-left: -10px;
                        }
                    }
                    .download:hover{
                        .downQRBox{
                            visibility: visible;
                            opacity:1;
                        }
                    }
                }
            }
        }
        .right {
            display: flex;
            align-items: center;
            .userInfor {
                padding-right: 20px;
                margin-right: 20px;
                border-right: 1px solid #333;
                font-size: 16px;
                color: #333;
            }
            .el-dropdown {
                display: flex;
                align-items: center;
                .lang {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: #333;
                    margin-right: 20px;
                    border-radius: 50%;
                    border: 2px solid #333;
                    font-size: 12px;
                    width: 25px;
                    height: 25px;
                    font-weight: bold;
                    transition: all 0.3s linear;
                }
                .lang:hover {
                    background-color: #333;
                    color: #fff;
                }
                .user {
                    width: 25px;
                    height: 25px;
                    background: url("../assets/images/profile_Black.png")
                        no-repeat center center / 25px;
                    margin-right: 20px;
                    transition: all 0.3s linear;
                }
                .user:hover {
                    background: url("../assets/images/profile_Black_hold.png")
                        no-repeat center center / 25px;
                }
                cursor: pointer;
                align-items: center;
                .ic {
                    width: 18px;
                    height: 18px;
                    margin-right: 10px;
                }
                .down {
                    margin-left: 10px;
                }
            }
        }
    }
}
.isabsolute {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 99;
    background-color: transparent;
    .headerContent {
        .left {
            .nav {
                ul {
                    li {
                        span {
                            color: #fff;
                        }
                        span.active {
                            color: #fff;
                        }
                        span.active::after {
                            border: 1px solid #fff;
                        }
                    }
                }
            }
        }
        .right {
            .el-dropdown {
                .lang {
                    color: #fff;
                    border-color: #fff;
                }
                .lang:hover {
                    background-color: #fff;
                    color: #000;
                }
                .user {
                    width: 25px;
                    height: 25px;
                    background: url("../assets/images/profile_white_hold.png")
                        no-repeat center center / 25px;
                    margin-right: 20px;
                }
                .user:hover {
                    background: url("../assets/images/profile_white.png")
                        no-repeat center center / 25px;
                }
            }
            .userInfor {
                color: #fff;
                border-color: #fff;
            }
        }
    }
}
</style>
